<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="3" lg="3">
          <v-autocomplete
            dense
            flat
            outlined
            v-model="params.ids"
            :items="listCoa"
            background-color="white"
            append-icon="mdi-magnify"
            label="Chart Of Account"
            multiple
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="2">
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Date"
                outlined
                flat
                dense
                background-color="white"
                v-model="params.date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.date" @input="menuDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="2">
          <v-btn @click="search" color="primary" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row v-for="(item, index) in items" :key="index">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="item"
            hide-default-footer
            dense
            disable-pagination
          >
            <template v-slot:item.no="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:item.amount="{ item }">
              {{ formatPrice(item.amount) }}
            </template>
            <template v-slot:item.rate="{ item }">
              {{ formatPrice(item.rate) }}
            </template>
            <template v-slot:item.amountIDR="{ item }">
              {{ formatPrice(item.amountIDR) }}
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="6" lg="3">
              <v-card outlined>
                <v-card-title class="py-0">Total Debit</v-card-title>
                <v-card-text class="text-right mt-3">
                  <span>{{ formatPrice(totalDebit(item)) }}</span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="3">
              <v-card outlined>
                <v-card-title class="py-0">Total Credit</v-card-title>
                <v-card-text class="text-right mt-3">
                  <span>{{ formatPrice(totalCredit(item)) }}</span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-divider></v-divider>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="items.length > 0">
      <v-btn color="primary" @click="save" class="mr-2">Save</v-btn>
      <v-btn color="error" @click="clear">Clear</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  name: "report-revaluation",
  data() {
    return {
      menuDate: false,
      params: {
        startDate: null,
        ids: [],
      },
      items: [],
      listCoa: [],
      headers: [
        {
          id: 1,
          text: "Reff",
          align: "left",
          sortable: false,
          value: "reference",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Account#",
          align: "left",
          sortable: false,
          value: "accountNumber",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Account Name",
          align: "left",
          sortable: false,
          value: "accountName",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Type",
          align: "center",
          sortable: false,
          value: "type",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Remark",
          align: "left",
          sortable: false,
          value: "remark",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Curr",
          align: "center",
          sortable: false,
          value: "currency",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Amount",
          align: "right",
          sortable: false,
          value: "amount",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Rate",
          align: "right",
          sortable: false,
          value: "rate",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "In RP",
          align: "right",
          sortable: false,
          value: "amountIDR",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    async search() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/getRevaluation", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(error => {
          if (error.response.status === 409) {
            this.$store.dispatch("toast", {
              type: "error",
              message: error.response.data,
            });
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
          }

          this.$store.commit("SET_LOADER", false);
        });
    },
    async getDropdownChartOfAccount() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("chartOfAccount/getDropDownChartOfAccountForeignExchange")
        .then(response => {
          this.listCoa = response.data;
          let date = moment();
          if (date.day() <= 5) {
            this.params.date = date
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD");
          } else {
            this.params.date = date.endOf("month").format("YYYY-MM-DD");
          }
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async save() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/createJournalRevaluation", this.items)
        .then(() => {
          window.location.reload();
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    clear() {
      (this.items = []), (this.params.id = 0);
    },
    totalDebit(items) {
      return items.length > 0
        ? items
            .filter(x => x.type === "Debit")
            .map(x => x.amountIDR)
            .reduce((x, y) => x + y)
        : 0;
    },
    totalCredit(items) {
      return items.length > 0
        ? items
            .filter(x => x.type === "Credit")
            .map(x => x.amountIDR)
            .reduce((x, y) => x + y)
        : 0;
    },
  },
  mounted() {
    this.getDropdownChartOfAccount();
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid grey;
}
</style>
